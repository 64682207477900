
.cont{
    margin-top: 3rem;
    margin-bottom: 1rem;
    height: 22rem;
    border-radius: 30px;
    /* margin-bottom: 3rem; */
    display: flex; 
    justify-content: center;
    align-items: center;
    background: #F5F5F5;

    /* background: url("../assets/Animated\ Shape.svg"); */
    /* background: #DCD7C9; */
    /* background-attachment: fixed; */
    /* background-blend-mode: color; */
    background-size: cover;
    width: 100%;
  }
  .counter-container {
    display: flex;  
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 30px 50px;
  }
  
  .counter {
    font-family: "Limelight", sans-serif;
    font-weight: 500;
    /* color: var(--gray700); */
    color: #754E1A;
    font-style: normal;
    font-size: 3rem;  
    text-align: center;
  }
  
  .count-txt{
    color: var(--black);
    font-size: 20px;
  font-weight: 500;
  text-wrap:balance;
  /* font-family: "Courgette", cursive; */
  /* font-weight: 400; */
  font-style: normal;
  }

   /* xs */
 @media screen and (max-width: 640px){
  .cont{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    align-items: center;
    justify-content: center;    

    .counter{
      font-size: 2em;
    }

    .count-txt{
      font-size: 17.5px;
      font-weight: 500;

    }

  }
}

 /* sm */
@media screen and (475px <= width <= 640px){
}

/* md */
@media screen and (640px <= width <= 768px){
  .cont{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    align-items: center;
    justify-content: center;    

    .counter{
      font-size: 3em;
    }

    .count-txt{
      font-size: 20px;
      font-weight: 600;
    }

  }
}

/* lg */
@media screen and (768px <= width <= 1280px){
}